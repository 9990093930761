import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Link from "gatsby-link"

const Logo = () => (
  <StaticQuery
    query={graphql`
      query {
        logoImage: file(relativePath: { eq: "images/web-passion-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 240) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => 
      <Link to="/">
        <Img fluid={data.logoImage.childImageSharp.fluid} />
      </Link>           
    }
  />
)

export default Logo
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Header from '../components/Header/subpage'

import '../components/scss/general.scss'

const Layout = props => (
  <div>
    <Helmet
      title={props.title}
      meta={[
        { name: 'description', content: `${ props.description }` },
        { name: 'keywords', content: `${props.keywords}` },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <Header 
      title={props.title}
      content={props.content}
    />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
import React from 'react'
import Layout from '../layout/subpage'

const NotFoundPage = () => (
  <Layout
    title="404 Page Not Found"
    description="Error 404 Page Not Found"
    keywords="404"
    content="There is nothing located here please <a href='/'>click here to return home</a>."
  />
)

export default NotFoundPage

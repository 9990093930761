import React from 'react'
import styled from 'styled-components'

import Logo from './logo'
import Illustration from './illustration'

import containerStyles from "../scss/container.module.scss"
import headerStyles from "../scss/header.module.scss"

const Header = props => {
    return (
        <div className={headerStyles.header} style={{ minHeight: '85vh' }}>
            <div className={containerStyles.container}>
                <div style={{
                    position: 'relative',
                    maxWidth: '240px',
                    zIndex: 60
                }}>
                    <Logo />
                </div>
                <IllustrationContainer>
                  <Illustration />
                </IllustrationContainer> 
                <div className={headerStyles.headerContent}>
                    <h1 dangerouslySetInnerHTML={{ __html: props.title }} />
                    <div dangerouslySetInnerHTML={{ __html: props.content }} />
                </div>
            </div>
            <PurplePolygon className="polygon"></PurplePolygon>
            {/* <GreyPolygon className="polygon"></GreyPolygon> */}
        </div>
    )
}

export default Header

const PurplePolygon = styled.div`
  background: linear-gradient(285deg, #8E2DE2 0%, #4A00E0 100%);
  /* clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%); */
  z-index: 20;
  /* @media (min-width: 1200px) {
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  }
  @media (min-width: 1200px) {
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 100%);
  }
  @media (min-width: 1600px) {
    clip-path: polygon(0 0, 100% 0, 100% 60%, 0% 100%);
  } */
`

// const GreyPolygon = styled.div`
//   background: #f9f9f9;
//   clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 90%);
//   z-index: 10;
//   @media (min-width: 1200px) {
//     clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 85%);
//   }
//   @media (min-width: 1600px) {
//     clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 80%);
//   }
// `

const IllustrationContainer = styled.div`
  display: none;
  position: fixed;
  right: 10%;
  top: -50px;
  width: 45%;
  z-index: 50;
  animation: MoveUpDown 3s linear infinite;
  @media (min-width: 768px) {
    display: block;
    right: 5%;
    top: 60px;
    width: 37.5%;
  }
  @media (min-width: 1400px) {
    right: 5%;
    top: 60px;
    width: 35%;
  }
  @media (min-width: 1600px) {
    right: 10%;
    top: 60px;
    width: 37.5%;    
  }
`